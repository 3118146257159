import 'babel-polyfill';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import 'font-awesome/css/font-awesome.css';

import '../css/style.css';
import '../css/recaptcha.css';

import 'react-datetime/css/react-datetime.css';

import 'jquery-ui/themes/base/all.css';

import Raven from 'raven-js';

Raven.config(window.server_variables.dsn).install();
